export const seo = {
  url: 'transport-intermodalny',
  title: {
    pl: 'Transport Intermodalny | Intermodal | Omida VLS',
    en: 'Intermodal Transport | Omida Transport of the future ',
  },
  desc: {
    pl: '✓ Transport intermodalny ✓ Intermodal ✓ Transport ekologiczny ✓ Nowe naczepy intermodalne ✓ Szybka wycena online.',
    en: 'Long-term contract, warranty, cargo safety, time optimization and up to 50% savings. Intermodal transport with an experienced TSL company.',
  },
  keywords: [
    'transport intermodalny',
    'intermodal',
    'długoterminowa umowa',
    'optymalizacja',
    'tsl',
  ],
}

export const intro = {
  title: {
    pl: 'Intermodal',
    en: 'Intermodal',
  },
  desc: {
    pl: 'Transport intermodalny to rozwiązanie dające gwarancje stałych kosztów na określonych trasach i warunkach. Jest to transport, w którym wykorzystywane jest wiele form transportu w jednej jednostce transportowej na całej trasie.',
    en: 'Intermodal Transport is a solution that allows you to reduce transport costs on specific routes and directions. It is a transport in which we use several forms of transport in one transport unit along the entire route.',
  },
  buttonSecond: {
    text: 'Wyślij zapytanie',
    link: '/transport-intermodalny/#formularz',
  },
  button: {
    text: 'Czytaj więcej',
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    pl: 'Transport intermodalny - transportem przyszłości',
    en: 'Intermodal transport - take advantage of the transport possibilities of the future',
  },
  texts: [
    {
      pl: `<span>W transporcie intermodalnym istnieje możliwość przygotowania <strong>długoterminowej umowy z gwarancją cenową</strong>. To rozwiązanie dedykowane dla firm chcących uzyskać gwarancję stabilności kosztów transportu przez cały rok.</span>`,
      en: `<span>In intermodal transport it is possible to prepare <strong>a long-term contract with a price guarantee</strong>. This solution is dedicated to companies that want to obtain a guarantee of stability of transport costs throughout the year.</span>`,
    },
    {
      pl: `<span>Zaletami transportu intermodalnego jest <strong>bezpieczeństwo ładunku i optymalizacja w łańcuchu dostaw</strong>. Aby lepiej zapoznać się z tematyką bezpieczeństwa ładunku, przeczytaj nasz wpis, który informuje o <a href="/blog/ubezpieczenia-w-transporcie/">ubezpieczeniu transportu</a>.</span>`,
      en: `<span>The advantages of intermodal transport are <strong>cargo safety and transport time optimization</strong>. The goods in motion are not exposed to unfavorable external factors.</span>`,
    },
    {
      pl: `<span>Łącząc wiele form transportu, umożliwiamy optymalizację dotychczasowych kosztów. Jeśli ładunek może być transportowany nieco dłużej to przy cyklicznych dostawach nasze rozwiązanie może przynieść <strong>nawet 50% oszczędności</strong>.</span>`,
      en: `<span>By combining various forms of transport, we enable the optimization of current costs. If the cargo can be transported a little longer, our solution can bring <strong>even 50% savings</strong> with cyclical deliveries.</span>`,
    },
  ],

  features: [
    {
      icon: 'eco',
      subtitle: {
        pl: '',
        en: '',
      },
      title: {
        pl: 'Ekologia',
        en: 'Ecology',
      },
      desc: {
        pl: 'Transport intermodalny pozwala zredukować emisję szkodliwych substancji dla Naszego zdrowia oraz dla Naszej planety nawet o 60%.',
        en: 'Intermodal transport allows to reduce the emission of harmful substances for our health and for our planet by up to 60%',
      },
    },
    {
      icon: 'shield',
      subtitle: {
        pl: '',
        en: '',
      },
      title: {
        pl: 'Bezpieczeństwo',
        en: 'Safety',
      },
      desc: {
        pl: 'Transport Intermodalny minimalizuje możliwość wystąpienia niepożądanych zdarzeń i sytuacji losowych - zniszczenia lub zagubienia towaru, wypadków i kradzieży.',
        en: 'Intermodal transport minimizes the possibility of undesirable events and random situations - damage or loss of goods, accidents and theft.',
      },
    },
    // {
    //   icon: 'time',
    //   subtitle: {
    //     pl: '',
    //     en: '',
    //   },
    //   title: {
    //     pl: 'Pewność',
    //     en: 'Confidence',
    //   },
    //   desc: {
    //     pl: 'Nasze rozwiązanie gwarantuje pewność realizacji transportów nawet w najbardziej intensywnym i trudnym okresie dla branży TSL.',
    //     en: 'Our solution guarantees the certainty of transport even in the most intense and difficult period for the TSL industry.',
    //   },
    // },
    {
      icon: 'dollar',
      subtitle: {
        pl: '',
        en: '',
      },
      title: {
        pl: 'Stabilność kosztów',
        en: 'Optimization',
      },
      desc: {
        pl: 'Transport intermodalny, dzięki terminowym kontraktom gwarantuje stałość ceny za transport Towarów - co często obniża koszty nawet do 50% w porównaniu do transportu drogowego.',
        en: 'Intermodal transport, thanks to timely contracts, guarantees a constant price for the transport of Goods - which often reduces costs by up to 50% compared to road transport.',
      },
    },
  ],
}

export const video = {
  title: {
    pl: 'Poznaj szczegóły nowego produktu Omida VLS',
    en: 'Get to know the details of the new Omida VLS product',
  },
  desc: {
    pl: 'W załączonym materiale Mateusz Kokosza opowiada o tym w jaki sposób obsługujemy usługi w Transporcie Intermodalnym.',
    en: 'In the attached material, Mateusz Kokosza talks about how we handle services in Intermodal Transport.',
  },
  url: 'https://www.youtube.com/watch?v=Y1sFnW3CYMg',
}

export const stages = {
  id: 'etapy',
  title: {
    pl: 'Procesy - Transportu Intermodal',
    en: 'Processes - Intermodal Transport',
  },
  desc: {
    pl: 'Poznaj procesy obsługi Omida Intermodal',
    en: 'Learn about the Omida Intermodal service processes',
  },
  stage: {
    pl: 'Etap',
    en: 'Stage',
  },
  stages: [
    {
      number: '01',
      title: {
        pl: 'Odbiór towaru',
        en: 'Reception',
      },
      body: {
        pl: '<ul><li>Na tym etapie odbieramy towar naszego klienta oraz transportujemy go za pomocą jednostek ładunkowych na terminal kontenerowy.</li> <li>Odbiory towarów w ramach transportu intermodalnego realizujemy w promieniu 300 km od terminali kolejowych.</li></ul>',
        en: `<ul><li>At this stage, we collect our client's goods and transport them by tractor and semi-trailer to the railway terminal.</li><li> We offer goods collection as part of intermodal transport within a radius of 300 km from the railway terminals.</li></ul>`,
      },
    },
    {
      number: '02',
      title: {
        pl: 'Dostawa na Terminal',
        en: 'Delivery to the Terminal',
      },
      body: {
        pl: '<ul><li>Po przejęciu przez naszego kierowcę towaru następuje dostawa załadowanej naczepy lub kontenera do terminalu.</li> <li>Kierowcy jeżdżący w kółkach na trasach obsługiwanych, w produkcie intermodalnym, uzyskują najlepsze czasy dostaw.</li></ul>',
        en: '<ul><li>After our driver takes over the goods, the loaded trailer is delivered to the terminal.</li><li> Drivers driving in wheels on the routes served, in an intermodal product, obtain the best delivery times.</li> </ul>',
      },
    },
    {
      number: '03',
      title: {
        pl: 'Mocowanie Ładunku',
        en: 'Lashing the Cargo',
      },
      body: {
        pl: `
        <ul>
          <li>Po dotarciu na miejsce załadunku następuje przekazanie naczepy lub kontenera na dedykowany wagon.</li>
          <li>W tym celu wykorzystywane są reach stackery i suwnice.</li>
          <li>W momencie załadunku naczepy na tabor kolejowy, kierowca ciągnika może realizować kolejne dostawy i odbiory towaru w promieniu 300 km od terminalu kolejowego.</li>
        </ul>
        `,
        en: `
        <ul>
          <li>After reaching the place of loading, the trailer is transferred to a dedicated wagon.</li>
          <li>Reach stackers and cranes are used for this purpose.</li>
          <li>When the semi-trailer is loaded onto the rolling stock, the tractor driver can make subsequent deliveries and pick-ups within a radius of 300 km from the railway terminal.</li>
      </ul>
        `,
      },
    },
    {
      number: '04',
      title: {
        pl: 'Podróż',
        en: 'Carriage',
      },
      body: {
        pl: `
        <ul>
          <li>O określonej godzinie, niemalże każdego dnia, cały skład kolejowy rusza z terminalu w celu przewiezienia towaru do miejsca docelowego. </li>
          <li>Kluczową przewagą w transporcie kolejowym jest ciągły ruch naszego towaru.</li>
        </ul>
        `,
        en: `
        <ul>
        <li>At a certain time, almost every day, the entire train depot starts from the terminal to transport the goods to their final destination.</li>
        <li>The key advantage in rail transport is the continuous movement of our goods.</li>
      </ul>
        `,
      },
    },
    {
      number: '05',
      title: {
        pl: 'Rozładunek',
        en: 'Unloading',
      },
      body: {
        pl: `
        <ul>
          <li>Ładunek trafia do terminalu docelowego, a następnie naczepa zostaje ściągnięta z wagonu i oczekuje na odbiór przez naszego kierowcę.</li>
          <li>Po podłączeniu naczepy z ciągnikiem ładunek zostaje dostarczony do miejsca odbioru zgodnie z wytycznymi ustalonymi z naszym klientem.</li>
        </ul>
        `,
        en: `
        <ul>
          <li>The cargo goes to the destination terminal, and then the trailer is unloaded from the wagon and awaits collection by our driver.</li>
          <li>After connecting the trailer to the tractor, the load is delivered to the pickup point in accordance with the guidelines agreed with our client.</li>
      </ul>
        `,
      },
    },
  ],
}

export const products = {
  id: 'produkty',
  title: {
    pl: 'Wybierz Transport Intermodalny i zyskaj przewagę w Biznesie',
    en: 'Choose Intermodal Transport and gain an advantage in Business',
  },
  desc: {
    pl: 'Wykorzystujemy różne metody transportu w tym <a href="/transport-drogowy/">transport drogowy</a>, <a href="/transport-morski/">morski</a> i <a href="/transport-kolejowy/">kolejowy</a>. Jedna jednostka wiele możliwości - zobacz jak to robimy!',
    en: 'One unit, many possibilities - see how we do it!',
  },
  products: [
    {
      image: 'road',
      name: {
        pl: 'Transport Drogowy',
        en: 'Road Transport',
      },
      description: {
        pl: 'Transport ładunków przemieszczających się po drogach lądowych przy pomocy pojazdów samochodowych. Usługi transportowe odbywające się z wykorzystaniem tej gałęzi transportu świadczone są przez przewoźników drogowych.',
        en: 'Transport of goods moving on land roads by means of motor vehicles. Transport services using this mode of transport are provided by road carriers.',
      },
      button: {
        pl: 'Wybierz Omida Intermodal',
        en: 'Try Omida Intermodal',
      },
      link: '/transport-intermodalny/#formularz',
    },
    {
      image: 'rail',
      name: {
        pl: 'Transport Kolejowy',
        en: 'Rail Transport',
      },
      description: {
        pl: 'Transport lądowy przemieszczający towary przy użyciu platform kolejowych w obrębie danej sieci kolejowej. Kolej jest formą transportu sztywno torowego, tzn. jej pojazdy mogą poruszać się tylko charakterystycznym dla danej technologii torem.',
        en: 'Inland transport that moves goods by rail vehicles along a given rail network. Railway is a form of rigidly track transport, i.e. its vehicles can only move on a track that is characteristic for a given technology.',
      },
      button: {
        pl: 'Wybierz Omida Intermodal',
        en: 'Try Omida Intermodal',
      },
      link: '/transport-intermodalny/#formularz',
    },
    {
      image: 'meritime',
      name: {
        pl: 'Transport Morski',
        en: 'Maritime  Transport',
      },
      description: {
        pl: 'Transport wodny obejmujący przewóz wyspecjalizowanymi statkami m.in. kontenerowcami, tankowcami i masowcami ładunków drogą morską. Transport morski jest również znany pod pojęciem Żeglugi morskiej.',
        en: 'Water transport including specialized ships, incl. container ships, tankers and bulk carriers by sea. Sea Transport is also known as Sea Shipping.',
      },
      button: {
        pl: 'Wybierz Omida Intermodal',
        en: 'Try Omida Intermodal',
      },
      link: '/transport-intermodalny/#formularz',
    },
  ],
}

export const industries = {
  title: {
    pl: 'Jakie branże korzystają z Transportu Intermodalnego?',
    en: 'Which industries use Intermodal Transport?',
  },
  desc: {
    pl: 'Poznaj przykłady wykorzystania intermodalu w utrzymaniu łańcucha dostaw. Transport intermodalny dedykujemy firmom działającym na rzecz ochrony środowiska oraz wysyłającym regularnie dużą ilość towarów do swoich kontrahentów. Wykorzystujemy ten model transportu, który dopasowujemy do <a href="/transport-branze/">odpowiedniej branży</a>.',
    en: 'See examples of the use of intermodal in maintaining the supply chain. We dedicate intermodal transport to companies operating for the protection of the environment, regularly sending large amounts of goods to their contractors.',
  },
  include: [
    'Motoryzacja',
    'Papiernicza',
    'Chemiczna',
    'Modowa',
    'Beauty',
    'Elektroniczna',
    'AGD',
    'Spożywcza',
    'Budowlana',
  ],
}

export const form = {
  id: 'formularz',
  title: {
    pl: 'Sprawdź jakie oszczędności możesz wygenerować wybierając Transport Intermodalny',
    en: 'Check what savings you can generate by choosing Intermodal Transport',
  },
  desc: {
    pl: '',
    en: '',
  },
  fields: {
    email: {
      label: { pl: 'Email', en: 'Email' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: true,
    },
    name: {
      label: { pl: 'Osoba Kontaktowa', en: 'Contact person' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: true,
    },
    message: {
      label: { pl: 'Wiadomość', en: 'Message' },
      placeholder: { pl: '', en: '' },
      type: 'message',
      isRequired: true,
    },
  },
  agreements: [
    {
      pl: 'Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowych w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną o treści pochodzących od Omida VLS Sp. z o.o.',
      en: 'I agree to receive by e-mail commercial information coming from Omida VLS Sp. z o.o. within the meaning of the Act of 18 July 2002 on electronic services',
    },
    {
      pl: 'Wyrażam zgodę na wykorzystywanie przez Omida VLS Sp. z o.o. telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących celem przedstawienia informacji handlowych w rozumieniu przepisów ustawy z dnia 16 lipca 2014 r. - Prawo telekomunikacyjne',
      en: 'I hereby consent to the use by Omida VLS Sp. z o.o. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
    },
  ],
  administrating: {
    pl: 'Administratorem Państwa danych osobowych będzie Omida VLS spółka z ograniczoną odpowiedzialnością (dalej: Omida). Można się z nami skontaktować w następujący sposób: listownie na adres: Aleja Grunwaldzka 472C, 80-309 Gdańsk, przez e-mail:',
    en: 'The administrator of your personal data will be Omida VLS spółka z ograniczoną odpowiedzialnością (hereinafter: Omida). You can contact us as follows: by letter to: Aleja Grunwaldzka 472C, 80-309 Gdańsk, by e-mail:',
  },
  administratingSecond: {
    pl: ' lub telefonicznie: +48 58 741 88 14. Dane osobowe będziemy przetwarzać w celu przygotowania ofert dotyczących produktów i usług Omida. Szczegółowe informacje na temat przetwarzania danych osobowych znajdziesz',
    en: 'or by phone: +48 58 741 88 14. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
  },
  administratingLinkText: {
    pl: 'tutaj',
    en: 'here',
  },
  dictonary: {
    success: {
      title: { pl: 'Dziękujemy!', en: 'Thank you!' },
      text: {
        pl: 'Twoja wiadomość została wysłana. Odpowiemy najszybciej jak to możliwe. Przesłaliśmy również potwierdzenie wiadomości na podany adres',
        en: 'Your message has been sent. We will reply as soon as possible. We also sent you the confirmation to provided email address',
      },
    },
    failure: {
      title: { pl: 'Niepowodzenie!', en: 'Failure!' },
      text: {
        pl: 'Wygląda na to, że Twoja wiadomość nie została wysłana. Skontaktuj się z nami drogą mailową lub telefonicznie',
        en: 'It looks like your message has not been sent. Contact us by e - mail or by phone',
      },
    },
    send: {
      pl: 'Wyślij',
      en: 'Send',
    },
    fill: {
      pl: 'Wypełnij formularz',
      en: 'Fill up the form',
    },
    fillagreements: {
      pl: 'Zaznacz zgody',
      en: 'Agree to the terms',
    },
  },
}

export const buttonBack = {
  text: {
    pl: 'Zapytaj o ofertę',
    en: 'Ask for an offer',
  },
  link: '/transport-intermodalny/#formularz',
}


export const intermodalPosts = {
  title: {
    pl: 'Aktualności Intermodal',
    en: 'Omida Intermodal Express News',
  },
  desc: {
    pl: '',
    en: '',
  },
}