import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  seo,
  intro,
  main,
  video,
  stages,
  products,
  industries,
  form,
} from '../content/transport-intermodalny'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Video from '../components/Video'
import Stages from '../sections/transport-intermodalny/Stages'
import Products from '../sections/transport-intermodalny/Products'
import IndexIndustries from '../sections/IndexIndustries'
import FormMail from '../components/FormMail'
import { sButton, sButtonGlow } from '../components/Button'
import { s } from '../style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Section from '../components/Section'
import IntermodalPosts from '../sections/intermodal/IntermodalPosts'
import IntermodalAdditionalText from '../sections/intermodal/IntermodalAdditionalText'
import Gallery from '../components/Gallery'

const TransportIntermodalny = () => {
  const { lang } = useLangContext()
  const query = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/transport-intermodalny.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: JPG
          )
        }
      }
      map: file(
        absolutePath: {
          regex: "/images/products/transport-intermodalny/intermodal_map.png/"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100 
            placeholder: TRACED_SVG 
            formats: WEBP
          )
        }
      }
      image1: file(
        absolutePath: { regex: "/images/intermodal/gallery/intermodal_1.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: TRACED_SVG
            formats: [WEBP]
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/intermodal/gallery/intermodal_2.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: TRACED_SVG
            formats: [WEBP]
          )
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image3: file(
        absolutePath: { regex: "/images/intermodal/gallery/intermodal_3.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: TRACED_SVG
            formats: [WEBP]
          )
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image4: file(
        absolutePath: { regex: "/images/intermodal/gallery/intermodal_4.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: TRACED_SVG
            formats: [WEBP]
          )
        }
      }
      image5: file(
        absolutePath: { regex: "/images/intermodal/gallery/intermodal_5.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: TRACED_SVG
            formats: [WEBP]
          )
        }
      }
      image6: file(
        absolutePath: { regex: "/images/intermodal/gallery/intermodal_6.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: TRACED_SVG
            formats: [WEBP]
          )
        }
      }
      image7: file(
        absolutePath: { regex: "/images/intermodal/gallery/intermodal_7.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: TRACED_SVG
            formats: [WEBP]
          )
        }
      }
      image8: file(
        absolutePath: { regex: "/images/intermodal/gallery/intermodal_8.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: TRACED_SVG
            formats: [WEBP]
          )
        }
      }
      image9: file(
        absolutePath: { regex: "/images/intermodal/gallery/intermodal_9.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 70
            placeholder: TRACED_SVG
            formats: [WEBP]
          )
        }
      }
    }
  `)

  const images = [
    query.image4,
    query.image1,
    query.image2,
    query.image3,
    query.image5,
    query.image6,
    query.image7,
    query.image8,
    query.image9,
  ]

  const galleryContent = {
    title: {
      pl: 'Galeria zdjęć',
      en: '',
    },
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/transport-intermodalny.jpg'}
        url={seo.url}
      />
      <Intro
        title={intro.title[lang]}
        desc={intro.desc[lang]}
        image={query.image.childImageSharp}
        logo
        h={2}
        button={intro.button}
        buttonSecond={intro.buttonSecond}
      />
      <Main data={main} h={1} />

      <Section bottomXS top mobile>
        <GatsbyImage
          image={getImage(query.map.childImageSharp)}
          alt={'Transport Intermodalny - Omida VLS'}
          objectFit='contain'
        />
      </Section>
      <IntermodalAdditionalText />
      <Gallery content={galleryContent} images={images} />
      <Video data={video} />
      <IndexIndustries
        include={industries.include}
        title={industries.title[lang]}
        desc={industries.desc[lang]}
      />
      <Stages data={stages} />

      <Products data={products} minimal />
      <FormMail data={form} template='OmidaIntermodal' />

      <IntermodalPosts />
      <div css={{ paddingBottom: '4rem' }} />
      <a href='tel:+48789406656' css={[sButton, sButtonGlow, sCallButton]}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-5 w-5'
          viewBox='0 0 20 20'
          fill='currentColor'>
          <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
        </svg>
      </a>
    </Layout>
  )
}

const sCallButton = {
  cursor: 'pointer',

  position: 'fixed',
  width: 64,
  height: 64,
  bottom: 32,
  left: 32,
  zIndex: 2000,
  marginBottom: 0,
  padding: 0,
  svg: { cursor: 'pointer', width: 32, height: 32 },
  [s.xs]: {
    width: 48,
    height: 48,
    svg: {
      width: 24,
      height: 24,
    },
  },
}

export default TransportIntermodalny
